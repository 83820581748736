// src/components/ForgotPassword.js

import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase'; // Ensure this path is correct

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Check your email for a password reset link.");
    } catch (error) {
      setError("Failed to send password reset email. " + error.message);
    }
  };

  return (
    <div className="auth-form">
      <h2>Reset Password</h2>
      {message && <div className="auth-form-success">{message}</div>}
      {error && <div className="auth-form-error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
}

export default ForgotPassword;
