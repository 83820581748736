// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import for authentication
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBE9KhgLA7Jj3UfmaK2kK5jvyJgAM6O4oI",
  authDomain: "unstrangled.firebaseapp.com",
  projectId: "unstrangled",
  storageBucket: "unstrangled.appspot.com",
  messagingSenderId: "329585360876",
  appId: "1:329585360876:web:d9370c3daff5e0657ecac9",
  measurementId: "G-Q5K8CMXNW0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Analytics (Optional)
const analytics = getAnalytics(app);

export { auth, db, analytics }; // Export the auth object