import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

function UserProfile() {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/signin');
      return;
    }

    const fetchUserData = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        console.log("No such document in Firestore!");
      }
    };

    fetchUserData();
  }, [navigate]);

  if (!userData) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-wrap md:flex-nowrap">
        <aside className="w-full md:w-1/4 p-4 bg-gray-100 rounded-lg">
          <img src={userData.profilePic || '/default-profile.jpg'} alt="Profile" className="w-32 h-32 rounded-full mx-auto mb-4" />
          <nav className="text-center">
            <ul>
              <li className="my-2">
                <a href="#change-password" className="text-blue-500 hover:text-blue-700">Change Password</a>
              </li>
              <li>
                <a href="#logout" className="text-blue-500 hover:text-blue-700">Logout</a>
              </li>
            </ul>
          </nav>
        </aside>
        <main className="flex-1 p-4">
          <h1 className="text-xl font-bold mb-4">Account Settings</h1>
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email address</label>
              <div className="p-2 bg-gray-200 rounded">{auth.currentUser.email}</div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">First name</label>
              <div className="p-2 bg-gray-200 rounded">{userData.firstName}</div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Last name</label>
              <div className="p-2 bg-gray-200 rounded">{userData.lastName}</div>
            </div>
            {/* Add more details here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default UserProfile;
