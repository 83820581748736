import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';

function TaskForm({ task = {} }) {
    const [formData, setFormData] = useState({
        title: task.title || '',
        description: task.description || '',
        priority: task.priority || 'Medium',
        status: task.status || 'New',
        category: task.category || 'Personal',
        startDate: task.startDate ? new Date(task.startDate).toISOString().slice(0, 16) : '',
        endTime: task.endTime ? new Date(task.endTime).toISOString().slice(0, 16) : '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFeedbackMessage('');

        try {
            await addDoc(collection(db, 'tasks'), {
                ...formData,
                startDate: new Date(formData.startDate),
                endTime: new Date(formData.endTime),
                userId: auth.currentUser.uid, // Add the user's ID to the task
            });
            setFeedbackMessage('Task saved successfully!');
            setFormData({
                title: '',
                description: '',
                priority: 'Medium',
                status: 'New',
                category: 'Personal',
                startDate: '',
                endTime: '',
            });
            setIsSubmitted(true);
        } catch (error) {
            console.error("Error adding task: ", error);
            setFeedbackMessage('Error saving task. Please try again.');
            setIsSubmitted(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddMore = () => {
        setIsSubmitted(false);
        setFeedbackMessage('');
    };

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };

    if (isSubmitted) {
        return (
            <div className="text-center p-4 max-w-lg mx-auto">
                <p>Task added successfully. Do you want to add another task?</p>
                <button
                    onClick={handleAddMore}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                    Add More
                </button>
                <button
                    onClick={handleGoToDashboard}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Go to Dashboard
                </button>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="p-4 max-w-lg mx-auto">
            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Title</label>
                <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Description</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Priority</label>
                <select
                    name="priority"
                    value={formData.priority}
                    onChange={handleChange}
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Status</label>
                <select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="New">New</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Category</label>
                <select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="Personal">Personal</option>
                    <option value="Professional">Professional</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Start Date/Time</label>
                <input
                    type="datetime-local"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-bold mb-2">End Date/Time</label>
                <input
                    type="datetime-local"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            {isLoading && <div>Loading...</div>}
            {feedbackMessage && <div className={`p-3 my-2 text-sm ${feedbackMessage.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>{feedbackMessage}</div>}

            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                disabled={isLoading}
            >
                Save Task
            </button>
        </form>
    );
}

export default TaskForm;
