import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Adjust the path as needed

function HomePage() {
  const { currentUser } = useAuth();

  return (
    <div className="flex flex-col justify-center items-center h-screen px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl sm:text-7xl lg:text-8xl text-slate-700 text-center py-4 font-bold">
          <span className="text-orange-500">u</span>nstrangle your life!
        </h1>
        <p className="text-lg sm:text-xl text-slate-600 text-center py-2 sm:py-4">
          Join thousands using unstrangled to effortlessly manage their lives.
        </p>
        
        {!currentUser ? (
          <form className="w-full max-w-lg space-y-4 py-4">
            <input className="form-input w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" type="text" placeholder="First Name" />
            <input className="form-input w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" type="text" placeholder="Last Name" />
            <input className="form-input w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" type="email" placeholder="Email Address" />
            <Link className="block w-full text-center px-7 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition duration-300 ease-in-out" to="/signup">
              Try It Free
            </Link>
          </form>
        ) : (
          <div className="text-lg sm:text-xl text-slate-700">
            Welcome back, {currentUser.displayName || currentUser.email}!
          </div>
        )}
    </div>
  );
}

export default HomePage;
