import React, { useState } from 'react';

function Calendar() {
    const [currentDate, setCurrentDate] = useState(new Date());

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    function renderDays() {
        const days = [];
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        for (let i = 0; i < 7; i++) {
            days.push(
                <div key={i} className="flex-1 text-center font-medium py-2 text-xs sm:text-sm">
                    {dayNames[i]}
                </div>
            );
        }

        return <div className="flex justify-between">{days}</div>;
    }

    function renderCells() {
        const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const startDate = new Date(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate() - monthStart.getDay());
        const endDate = new Date(monthEnd.getFullYear(), monthEnd.getMonth(), monthEnd.getDate() + (6 - monthEnd.getDay()));
    
        // Normalize the time part to ensure accurate comparison for 'today'
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const rows = [];
        let days = [];
        let day = new Date(startDate);
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const dayCopy = new Date(day);
                const dayIsToday = dayCopy.setHours(0, 0, 0, 0) === today.getTime();
    
                days.push(
                    <div
                        key={`${dayCopy.getFullYear()}-${dayCopy.getMonth()}-${dayCopy.getDate()}-${i}`}
                        className={`flex-1 py-2 text-center text-xs sm:text-sm ${dayCopy.getMonth() !== currentDate.getMonth() ? "text-gray-400" : dayIsToday ? "text-white bg-blue-500 rounded-full" : "text-gray-700"}`}
                    >
                        {dayCopy.getDate()}
                    </div>
                );
                day.setDate(day.getDate() + 1);
            }
            rows.push(<div key={day.getTime()} className="flex flex-wrap justify-between">{days}</div>);
            days = [];
        }
    
        return <div className="mt-4">{rows}</div>;
    }

    return (
        <div className="border border-gray-300 rounded-lg p-4 bg-white">
            <div className="flex flex-wrap justify-between items-center mb-4 text-xs sm:text-sm">
                <button className="py-1 px-3 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={prevMonth}>Prev</button>
                <div className="font-bold">
                    {currentDate.toLocaleDateString("default", { month: "long", year: "numeric" })}
                </div>
                <button className="py-1 px-3 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={nextMonth}>Next</button>
            </div>
            {renderDays()}
            {renderCells()}
        </div>
    );
}

export default Calendar;
