import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { auth } from './firebase';
import { signOut } from "firebase/auth";

// Import your components here
import HomePage from './components/HomePage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import ForgotPassword from './components/ForgotPassword';
import UserProfile from './components/UserProfile';
import UserProfileEdit from './components/UserProfileEdit';
import Dashboard from './components/DashBoard';
import TaskForm from './components/TaskForm';

// Import your CSS here
import './index.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Router>
      <AuthProvider value={{ currentUser }}>


        <div className="container mx-auto bg-white text-slate-900 text-lg h-screen">
          {/* Navigation Bar */}
          <nav className="bg-slate-500 text-white flex justify-between px-4 sm:px-6 lg:px-8">
            {/* Logo and Brand Name */}
            <div className="flex items-center">
              <a href="/" className="flex text-2xl font-semibold">
                <p className="text-orange-400">u</p>nstrangled<p className="text-gray-400">.com</p>
              </a>
            </div>

            {/* Links / Buttons */}
            <div className="sm:flex items-center space-x-4">
              {!currentUser ? (
                <>
                  <Link to="/signup">register</Link>
                  <Link to="/signin">sign in</Link>
                </>
              ) : (
                <div>
                  <button onClick={() => setShowDropdown(!showDropdown)}>
                    {currentUser.displayName || currentUser.email}
                  </button>
                  {showDropdown && (
                    <div className="absolute mt-2 py-2 w-48 bg-white rounded-lg shadow-xl">
                      <Link to="/userprofile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</Link>
                      <Link to="/edit-profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Edit Profile</Link>
                      <button onClick={handleSignOut} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign Out</button>
                    </div>
                  )}
                </div>
              )}
              {/* Add other links if necessary */}
            </div>


          </nav>





          {/* Routes */}
          <Routes>
            <Route path="/" element={currentUser ? <Dashboard /> : <HomePage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/userprofile" element={currentUser ? <UserProfile /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile" element={<UserProfileEdit />} />
            <Route path="/add-task" element={<TaskForm />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Other routes remain the same */}
          </Routes>

        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
