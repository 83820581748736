import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import Calendar from './Calendar';
import TaskDisplay from './TaskDisplay';

function Dashboard() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="mx-auto py-4">
      <header className="bg-blue-100 text-slate-700 p-6 rounded-lg shadow-md mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold mb-2">Welcome, {currentUser ? currentUser.displayName : 'Guest'}</h1>
        <p>Here is your dashboard for today</p>
        {/* Profile Picture Here */}
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        
        <div className="md:col-span-1 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg sm:text-xl font-bold">Your Calendar</h2>
          <Calendar />
        </div>

        <div className="md:col-span-1 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg sm:text-xl font-bold">Upcoming tasks</h2>
          <TaskDisplay />
        </div>
      </div>


    </div>
  );
}

export default Dashboard;
