import React, { useState } from 'react';
import { auth, db } from '../firebase'; // adjust path if necessary
import { doc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";

function UserProfileEdit() {
  const [displayName, setDisplayName] = useState(auth.currentUser.displayName || '');
  const [age, setAge] = useState(''); // New state for age
  const [profilePic, setProfilePic] = useState(null); // New state for profile picture
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleUpdateProfile = async () => {
    try {
      if (displayName !== auth.currentUser.displayName) {
        await updateProfile(auth.currentUser, { displayName });
        setMessage('Display name updated successfully.');
      }

      // Update age and profile picture in Firestore
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const updatedData = { ...(age && { age }) };
      if (profilePic) {
        // Handle file upload here and then set the profilePic URL in updatedData
      }
      if (Object.keys(updatedData).length > 0) {
        await updateDoc(userDocRef, updatedData);
        setMessage('Profile updated successfully.');
      }
    } catch (err) {
      setError('Failed to update profile: ' + err.message);
    }
  };

  return (
    <div className="profile-container">
      <aside className="profile-sidebar">
        {/* Profile Picture and Navigation Links */}
        {/* ... */}
      </aside>
      <main className="profile-main">
        <h1>Edit Profile</h1>
        <div className="account-details">
          {message && <div className="success-message">{message}</div>}
          {error && <div className="error-message">{error}</div>}

          <div className="detail-item">
            <label>Display Name:</label>
            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
          </div>

          <div className="detail-item">
            <label>Age:</label>
            <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />
          </div>

          <div className="detail-item">
            <label>Profile Picture:</label>
            <input type="file" onChange={(e) => setProfilePic(e.target.files[0])} />
          </div>

          <button onClick={handleUpdateProfile}>Update Profile</button>
        </div>
      </main>
    </div>
  );
}

export default UserProfileEdit;
