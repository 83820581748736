import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase'; // Make sure this path is correct
import { useNavigate, Link } from 'react-router-dom';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      navigate('/userprofile'); // Redirect to user profile if already signed in
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/userprofile'); // Redirect to user profile after successful sign-in
    } catch (error) {
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        setError('Incorrect email or password. Please try again.');
      } else {
        setError('Failed to sign in. ' + error.message);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center py-8 px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-bold text-slate-700 p-4">Sign In</h2>
      {error && <div className="px-4 py-2 text-red-600 border border-red-600 rounded-lg">{error}</div>}
      <form className="w-full max-w-md space-y-4 p-4" onSubmit={handleSubmit}>
        <input className="form-input w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input className="form-input w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit" className="block w-full text-center px-7 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition duration-300 ease-in-out">
          Sign In
        </button>
      </form>
      <div className="text-slate-600">
        <Link to="/forgot-password" className="text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">Forgot Password?</Link>
      </div>
    </div>
  );
}

export default SignIn;
