import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Ensure Firebase is imported
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { format, differenceInMinutes } from 'date-fns';

function TaskDisplay() {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [expandedTaskId, setExpandedTaskId] = useState(null);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchTasks(user.uid);
            } else {
                setTasks([]);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchTasks = async (userId) => {
        setLoading(true);
        try {
            const q = query(collection(db, 'tasks'), where('userId', '==', userId));
            const querySnapshot = await getDocs(q);
            const tasksData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTasks(tasksData);
        } catch (error) {
            console.error('Error fetching tasks: ', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDateTime = (dateTime) => {
        if (!dateTime) return 'N/A';
        try {
            const date = new Date(dateTime);
            if (!isNaN(date.getTime())) {
                return format(date, 'PPpp');
            } else {
                return 'Invalid date';
            }
        } catch (e) {
            console.error('Error parsing date:', e);
            return 'Invalid date';
        }
    };

    const calculateDuration = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            return 'N/A';
        }
        return differenceInMinutes(endDate, startDate);
    };

    const toggleExpanded = (taskId) => {
        setExpandedTaskId(expandedTaskId === taskId ? null : taskId);
    };

    const handleStatusChange = async (taskId, newStatus) => {
        console.log(`Updating task ${taskId} to status ${newStatus}`);

        // Update the task's status in the local state
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId) {
                return { ...task, status: newStatus };
            }
            return task;
        });
        setTasks(updatedTasks);

        // Update the task's status in the database
        try {
            const taskRef = doc(db, 'tasks', taskId);
            await updateDoc(taskRef, { status: newStatus });
            console.log('Update successful');
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    return (
        <div className="overflow-y-auto max-h-80">
            {loading ? (
                <p className="text-center">Loading tasks...</p>
            ) : (
                tasks.length > 0 ? (
                    tasks.map(task => (
                        <div key={task.id} className={`mb-4 p-4 border rounded shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${
                            task.status === 'new' ? 'bg-blue-100 border-blue-200' :
                            task.status === 'inProgress' ? 'bg-yellow-100 border-yellow-200' :
                            'bg-green-100 border-green-200'
                        }`}>
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold" onClick={() => toggleExpanded(task.id)}>
                                    {task.title}
                                </h3>
                                <select 
                                    value={task.status} 
                                    onChange={(e) => handleStatusChange(task.id, e.target.value)}
                                    className="p-1 rounded"
                                >
                                    <option value="new">New</option>
                                    <option value="inProgress">In Progress</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                            {expandedTaskId === task.id && (
                                <div className="mt-4">
                                    <p>Description: {task.description}</p>
                                    <p>Priority: {task.priority}</p>
                                    <p>Category: {task.category}</p>
                                    {/* Include any other details you want to show */}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="text-center">No tasks found.</p>
                )
            )}
        </div>
    );
    
}

export default TaskDisplay;